<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card>
      <v-card-title class="form-modal-header">
        <v-btn color="white" text @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span class="text-h5 white--text"> Atualizar Registro </span>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="update"> Atualizar </v-btn>
      </v-card-title>
      <v-card-text class="mt-12">
        <v-container>
          <v-row>
            <v-col cols="12" md="6" class="col-farm">
              <v-toolbar elevation="0" style="background: none">
                <v-toolbar-title class="font-weight-bold">
                  Selecionar Fazenda
                </v-toolbar-title>
              </v-toolbar>

              <v-container class="custom-container">
                <v-row>
                  <v-col cols="12">
                    <custom-label label="Proprietário" />
                    <simple-select
                      :items="producers"
                      :disabled="true"
                      v-model="payload.producer_id"
                      placeholder="Produtor"
                      itemText="user.name"
                      itemValue="producer_id"
                      height="auto"
                      @input="loadFarms($event)"
                    />
                  </v-col>

                  <v-col>
                    <custom-label label="Fazenda" />
                    <simple-select
                      :items="financialRecord.farmsByProducer.data"
                      :disabled="true"
                      v-model="payload.farm_id"
                      placeholder="Fazenda"
                      itemText="name"
                      itemValue="id"
                      height="auto"
                      @input="loadFarmDetails($event)"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4">
                    <p>Tamanho Fazenda</p>
                    <p>{{ farmDetails.farm_size }} ha</p>
                  </v-col>

                  <v-col cols="3">
                    <p>Safra</p>
                    <p>
                      {{ financialRecord.harvest }}
                    </p>
                  </v-col>

                  <v-col cols="5">
                    <p>AS Responsável</p>
                    <p>{{ getAS() }}</p>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>

            <v-col cols="12" md="6" class="col-index">
              <v-toolbar elevation="0" style="background: none">
                <v-toolbar-title class="font-weight-bold">
                  Índices Financeiros
                </v-toolbar-title>
              </v-toolbar>

              <v-container class="custom-container">
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    v-for="financialIndex in financialRecord.indexes"
                    :key="financialIndex.id"
                  >
                    <custom-label :label="financialIndex.name" />
                    <simple-select
                      v-model="selectedIndex[financialIndex.id]"
                      :items="grades"
                      placeholder="Selecione uma opção"
                      :itemValue="financialIndex.id.toString()"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FinancialRecords from "@/domain/records/financial-records/financial-records";
import Farms from "@/domain/farms/farms";
import Producers from "@/domain/producers/producers";

import SimpleSelect from "@/components/inputs/SimpleSelect";
import CustomLabel from "@/components/texts/CustomLabel";

export default {
  name: "FinancialRecordFarmEdit",
  components: { SimpleSelect, CustomLabel },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },

    financialRecord: {
      type: Object,
      default: () => {},
    },

    producerSelected: {
      type: Number,
    },

    farmSelected: {
      type: Number,
    },
  },
  data: () => ({
    payload: {},

    financialRecordService: null,
    farmService: null,
    producerService: null,

    producers: [],
    farms: [],
    farmDetails: {},
    grades: ["N/A", "6.0", "6.5", "7.0", "7.5", "8.0", "8.5", "9.0", "9.5", "10.0"],
    selectedIndex: [],
  }),

  methods: {
    loadProducers() {
      this.producerService.all().then((result) => {
        result.data.map((item) => {
          this.producers.push(item);
        });
      });

      this.payload.producer_id = this.producerSelected;
      this.payload.farm_id = this.farmSelected;

      this.financialRecord.indexes.forEach((item) => {
        this.selectedIndex[item.id] = item.grade;
      });

      this.loadFarmDetails(this.farmSelected);
    },

    loadFarms(producerId) {
      const loader = this.$loading.show();
      this.farms = [];

      this.farmService.listByProducer(producerId).then((result) => {
        result.data.map((item) => {
          this.farms.push(item);
        });
      });

      loader.hide();
    },

    loadFarmDetails(farmId) {
      const loader = this.$loading.show();
      this.farmDetails = {};

      this.farmService.getFarmDetails(farmId).then((result) => {
        this.farmDetails = result.data;
      });

      loader.hide();
    },

    toggleScrollBar(param) {
      let html = document.querySelector('html');
      html.setAttribute("style", `overflow: ${param};`);
    },

    close() {
      this.toggleScrollBar('auto');
      this.$emit("closeDialog");
    },

    update() {
      const loader = this.$loading.show();

      this.payload.harvest = this.financialRecord.harvest;

      this.selectedIndex = this.selectedIndex.map(item => {
        if (item == "N/A") item = null;
        return item;
      });

      Object.assign(this.payload.indexes, this.selectedIndex);

      this.financialRecordService
        .edit(this.payload)
        .then(() => {
          loader.hide();
          this.close();

          this.$toasted.show("Registro financeiro cadastrado com sucesso.", {
            type: "success",
          });
        })
        .catch(({ response }) => {
          loader.hide();
          const { error } = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        });
    },

    getAS() {
      const producer = this.farmDetails.producer;
      return producer ? producer.responsible_success_advisor.name : "";
    },
  },

  watch: {
    dialog: {
      immediate: true,
      handler () {
        this.toggleScrollBar('hidden');
      }
    }
  },

  mounted() {
    this.loadProducers();
  },

  beforeMount() {
    this.payload = FinancialRecords.newData();
    this.financialRecordService = FinancialRecords;
    this.farmService = Farms;
    this.producerService = Producers;
  },
};
</script>

<style scoped>
::v-deep .col-farm .v-toolbar__content {
  padding: 0;
}

::v-deep .col-index .v-toolbar__content {
  padding: 0;
}

.custom-container {
  border: 1px solid #e9d8a6;
  border-radius: 5px;
  padding: 1.5rem 2rem 0.75rem 2rem;
}

p {
  color: #000000;
}
</style>
