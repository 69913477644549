<template>
  <div>
    <div class="mt-4 font-weight-bold">
      <v-icon @click="closeView()"> mdi-arrow-left </v-icon>&nbsp;
      <span style="margin-left: 0.5rem;">Voltar</span>
    </div>

    <v-toolbar class="mt-4 mb-4" elevation="0" style="background: none">
      <v-toolbar-title>
        <span class="font-weight-bold">Registro Financeiro</span>
        <span> / {{ getFarm }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <!-- Informações gerais -->
    <v-card class="mb-12 rounded-xl">
      <v-card-title class="yellow-border">
        <v-container class="custom-container">
          <v-row align="center">
            <v-col class="d-flex justify-start">
              <span class="font-weight-bold">Informações Gerais</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <p>
                <span><b>Proprietário</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ financialRecord.producer }}
                </span>
              </p>
            </v-col>
            <v-col cols="4">
              <p>
                <span><b>Fazenda</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ getFarm }}
                </span>
              </p>
            </v-col>
            <v-col cols="4">
              <p>
                <span><b>Safra</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ financialRecord.harvest }}
                </span>
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <p>
                <span><b>AS Responsável</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ getAS }}
                </span>
              </p>
            </v-col>
            <!--
            <v-col cols="4">
              <p>
                <span><b>Cultura</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ financialRecord.culture }}
                </span>
              </p>
            </v-col>
            -->

            <v-col cols="4">
              <p>
                <span><b>Data do Registro</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ financialRecord.recordDate | date }}
                </span>
              </p>
            </v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container mt-6">
          <v-row align="center">
            <v-col class="d-flex justify-start">
              <span class="font-weight-bold">Índices Financeiros</span>
            </v-col>

            <v-spacer></v-spacer>

            <v-col class="d-flex justify-end">
              <v-icon
                v-if="authoritySlug === 'super-admin' || permissions.access_records"
                @click="editFinancialRecordFarm()"> mdi-pencil </v-icon>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="6"
              v-for="financialIndex in financialRecord.indexes"
              :key="financialIndex.id"
            >
              <span class="font-weight-light">
                <p :class="verifyGrade(financialIndex.grade)">
                  {{ financialIndex.name }}: {{ financialIndex.grade || "N/A" }}
                </p>
              </span>
            </v-col>

            <v-col cols="6">
              <p class="font-weight-bold">Índice de Qualidade: {{ financialRecord.recordIql }}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
    </v-card>

    <financial-record-farm-edit
        v-if="showFinancialRecordFarmEdit"
        :dialog="showFinancialRecordFarmEdit"
        :financialRecord="financialRecord"
        :producerSelected="financialRecord.farmDetails.data.producer.id"
        :farmSelected="financialRecord.farmDetails.data.id"
        @closeDialog="resetFarm()"
    />

  </div>
</template>

<script>
import LoginService from "@/domain/login/login.service";
import FinancialRecords from "@/domain/records/financial-records/financial-records";

import FinancialIndexes from "@/domain/indexes/financial-indexes/financial-indexes";
import Contracts from "@/domain/contracts/contracts";

import FinancialRecordFarmEdit from "./FinancialRecordFarmEdit.vue";

import {getItem} from '@/services/local-storage.service';

export default {
  name: "FinancialRecordDetails",
  components: {FinancialRecordFarmEdit},
  data: () => ({

    authority: true,
    adminId: [1, 2],

    showFinancialRecordFarmEdit: false,

    financialRecordService: null,
    financialIndexService: null,
    contractService: null,

    payload: {},
    farmId: "",
    harvest: "",
    financialRecord: {},
    dialog: false,
    financialIndexes: [],
    financialIndexesByField: {},
    payloadIndexes: {},

    authoritySlug: "",
    permissions: ""
  }),

  computed: {
    getAS() {
      const record      = this.financialRecord;
      const conditional = record.farmDetails && record.farmDetails.data;

      return conditional 
        ? record.farmDetails.data.producer.responsible_success_advisor.name 
        : '';
    },

    getFarm() {
      const farm = this.financialRecord.farm;

      return JSON.stringify(farm) !== JSON.stringify({})
        ? farm 
        : '';
    },

    getProducerId() {
      const record = this.financialRecord;
      return record.farmDetails.data.producer.id;
    },

    isViewingAdmin() {
      return this.authority;
    }
  },

  methods: {
    getUserLogged() {
      const loader = this.$loading.show();

      LoginService
      .getUserLogged()
      .then((data) => {

        if (!this.adminId.includes(data.authority_id)) {
          this.authority = false;
        }

        loader.hide();
      })
      .catch(() => {
        location.reload();
        loader.hide();
      });
    },

    loadFinancialRecord() {
      const loader = this.$loading.show();

      this.financialRecordService
        .get(this.farmId, this.harvest)
        .then((result) => {
          loader.hide();
          this.financialRecord = result;
        })
        .catch(({ response }) => {
          loader.hide();

          const { error } = response.data;

          this.$router.push({ name: "financial-records" });
          this.$toasted.show(error, {
            type: "error",
          });
        });
    },

    async loadIndexes() {
      const loader          = this.$loading.show();
      this.financialIndexes = [];
      this.payloadIndexes   = {};

      await this.contractService
        .enabledIndexesByFinancialAndProducer(this.getProducerId)
        .then(result => {
          result.data.map(item => {
            this.financialIndexes.push(item);
            this.payloadIndexes[item.index.id] = null;
          });
      });

      loader.hide();
    },

    editFinancialRecordFarm() {
      this.showFinancialRecordFarmEdit = true;
    },

    resetFarm() {
      this.dialog = false;
      this.showFinancialRecordFarmAdd = false;
      this.showFinancialRecordFarmEdit = false;
      this.loadFinancialRecord();
    },

    closeView() {
      this.$router.push({ name: "financial-records" });
    },

    verifyGrade(grade) {
      return !grade && "no-grade";
    },
  },

  mounted() {
    this.farmId  = this.$route.params.farmId;
    this.harvest = this.$route.params.harvest;

    this.loadFinancialRecord();
    this.loadFinancialRecord();
  },

  beforeMount() {
    this.getUserLogged();
    this.payload                = FinancialRecords.newData();
    this.financialRecordService = FinancialRecords;
    this.financialIndexService  = FinancialIndexes;
    this.contractService        = Contracts;

    this.authoritySlug = getItem('authoritySlug');
    this.permissions   = JSON.parse(getItem('permissions'));
  },
};
</script>

<style scoped>
  .custom-container {
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    padding: 1.5rem 2rem 0.75rem 2rem;
  }

  p {
    color: #000000;
  }

  .no-grade {
    color: red;
    font-weight: bold;
  }

  ::v-deep .v-toolbar__content{
    padding: 0 !important;
  }
</style>
